export const SDKRefItems = [
  {
    title: 'Client-side',
    child: [
      {
        title: 'JavaScript',
        image: '',
        description:
            'References for the JavaScript SDK.',
        link: '/sdks/skyflow-js/modules/Skyflow/',
      },
      {
        title: 'React',
        image: '',
        description:
            'References for the React SDK.',
        link: '/sdks/skyflow-react-js/Overview/',
      },
      {
        title: 'React Native',
        image: '',
        description:
            'References for the React Native SDK.',
        link: '/sdks/skyflow-react-native/Overview/',
      },
      {
        title: 'Android',
        image: '',
        description: 'References for the Android SDK.',
        link: '/sdks/skyflow-android/Overview',
      },
      {
        title: 'iOS',
        image: '',
        description: 'References for the iOS SDK.',
        link: '/sdks/skyflow-iOS/Client',
      }
    ]
  },
  {
    title: 'Server-side',
    child: [
      {
        title: 'Java',
        image: '',
        description: 'References for the Java SDK.',
        link: '/sdks/skyflow-java/Overview',
      },
      {
        title: 'Node.js',
        image: '',
        description: 'References for the Node.js SDK.',
        link: '/sdks/skyflow-node/modules/Skyflow',
      },
      {
        title: 'Python',
        image: '',
        description: 'References for the Python SDK.',
        link: '/sdks/skyflow-python/skyflow',
      },
      {
        title: 'Go',
        image: '',
        description: 'References for the Go SDK.',
        link: '/sdks/skyflow-go/client',
      }
    ]
  }
];
