import React from 'react';
import { SDKRefItems } from '../utils/SDKRefs';
import ReferencePage from '../components/common/ReferencePage';
export { getServerData } from './index';

const SDKRefPage: React.FC = () => {
  return (
    <ReferencePage
      title="SDK references"
      description="Integrate with Skyflow's client-side and server-side SDKs."
      header1={SDKRefItems[0].title}
      header2={SDKRefItems[1].title}
      items={SDKRefItems[0].child}
      items1={SDKRefItems[1].child}
    ></ReferencePage>
  );
};

export default SDKRefPage;
